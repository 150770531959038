<template>
  <div id="languages">
    <div class="languages-loader" v-if="loading"></div>

    <LanguagesAddNewPopup />
    <LanguagesSelector />
    <LanguagesFields />
  </div>
</template>

<script>
/* eslint-disable*/
import { mapState, mapGetters } from "vuex";
export default {
  name: "Languages",
  data() {
    return {};
  },
  components: {},
  computed: {
    ...mapState({
      loading: (state) => state.languages.loading,
    }),
    ...mapGetters("languages", []),
  },
  created() {},
  methods: {},
};
</script>
